import { IOrder, Order, OrderStates } from '../../models/order.type';

export class UtilService {
    public getTimestampStringWithMinutesPrecision(date: Date | undefined): string {
        if (date) return date.toISOString().substring(0, 16);
        else return '';
    }

    public isStateShippedOrRejectedAndNoAdmin(order: IOrder, isAdmin: boolean): boolean {
        const orderState: OrderStates | string = this.isNumber(order.details.state!)
            ? OrderStates[order.details.state!]
            : (order.details.state as OrderStates);
        
        return (
            (orderState.toString() === OrderStates[OrderStates.Rejected] || orderState.toString() === OrderStates[OrderStates.Shipped]) && !isAdmin
        );
    }

    public isNumber(value: string | number): boolean {
        return typeof value === 'number';
    }
}
