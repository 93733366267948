<dx-popup
    id="complaint-dialog"
    [(visible)]="showComplaintDialog"
    [hideOnOutsideClick]="false"
    [title]="'COMPLAINT_DIALOG.TITLE' | translate"
    [dragEnabled]="false"
    height="auto"
    [width]="450"
    (onHiding)="handleComplaintDialogCloseEvent()"
    [resizeEnabled]="false"
>
    <app-complaint-dialog [complainedOrder]="getOrderFromOrderResultList(complainedOrderId)" (closeDialog)="handleComplaintDialogCloseEvent($event)" />
</dx-popup>

<div class="data-grid-container" [ngClass]="{ disabled: showComplaintDialog }">
    <div *ngIf="canChangeDeliveryDate" class="bulk-edit-actions">
        <dx-switch [(value)]="showSelectionCheckboxes" (onValueChanged)="clearSelectedSearchResultItems()" [height]="35"> </dx-switch>

        <dx-button
            icon="edit"
            *ngIf="showSelectionCheckboxes"
            [disabled]="!selectedSearchResultItems.length"
            (onClick)="openBulkEditPopup()"
            [height]="25"
        >
        </dx-button>

        <div>{{ 'ORDER_BULK_EDIT.SLIDER_LABEL' | translate }}</div>
    </div>

    <dx-data-grid
        [dataSource]="datasource"
        [showBorders]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        [columnAutoWidth]="true"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onSelectionChanged)="onOrdersSelectionChanged($event)"
        (onRowDblClick)="onRowClickEventWitRowData($event)"
        (onRowPrepared)="onRowPrepared($event)"
        [remoteOperations]="true">
        
        <dxo-scrolling showScrollbar="always" />
        <dxo-sorting mode="none" />
        <dxo-pager [allowedPageSizes]="[20]" [visible]="true" [showInfo]="true"></dxo-pager>

        <dxo-selection selectAllMode="allPages" mode="multiple" [showCheckBoxesMode]="showSelectionCheckboxes ? 'always' : 'none'"> </dxo-selection>

        <dxi-column dataField="orderNumber" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.ORDER_ID' | translate }}"
                    dataType="string" [fixed]=true [width]="110" />
        <dxi-column dataField="materialNumber" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.MATERIAL' | translate }}"
                    dataType="string" />
        <dxi-column dataField="productCategory"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.PRODUCTCATEGORY' | translate }}" cellTemplate="productCategory"
                    alignment="left" [width]="100" />
        <dxi-column dataField="recipePriority"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RECIPEPRIORITY' | translate }}" dataType="number" [width]="90"
                    alignment="left" />
        <dxi-column dataField="recipeData.ringDiameter"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RINGDIAMETER' | translate }}" cellTemplate="ringDiameter"
                    alignment="left" cssClass="cell-highlighted" [width]="100" />
        <dxi-column dataField="recipeData.holeNumber"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.HOLENUMBER' | translate }}" alignment="left"
                    cssClass="cell-highlighted" [width]="60" />
        <dxi-column name="targetRingThicknesses" dataField="id"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RINGTHICKNESSES_TARGET' | translate }}"
                    cellTemplate="ringThicknessTarget" cssClass="cell-highlighted" [width]="100" />
        <dxi-column name="actualRingThicknesses" dataField="id"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RINGTHICKNESSES_ACTUAL' | translate }}"
                    cellTemplate="ringThicknessActual" [width]="100" />
        <dxi-column dataField="set" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.SET' | translate }}" dataType="string"
                    [width]="50" />
        <dxi-column dataField="objectiveType"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.OBJECTIVE_TYPE' | translate }}" dataType="string"
                    [width]="85" />
        <dxi-column name="deliveryDate" dataField="id"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.DESIRED_DELIVERY_DATE' | translate }}"
                    cssClass="cell-highlighted" [width]="130" cellTemplate="deliveryDate" />
        <dxi-column name="predictedDeliveryDate" dataField="id"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.PREDICTED_DELIVERY_DATE' | translate }}"
                    cssClass="cell-highlighted" [width]="130" cellTemplate="predictedDeliveryDate" />
        <dxi-column name="state" dataField="id" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.STATE' | translate }}"
                    cellTemplate="state" />
        <dxi-column dataField="needsPickup" caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.NEEDSPICKUP' | translate }}"
                    cellTemplate="needsPickup" alignment="left" [width]="65" />
        <dxi-column dataField="ordererLastname"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.BESTELLAUSLOESER' | translate }}" dataType="string" />
        <dxi-column dataField="ordererDepartment"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.DEPARTMENT' | translate }}" dataType="string"
                    cssClass="cell-highlighted" cellTemplate="ordererDepartment" />
        <dxi-column name="received" dataField="id"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.RECEIVED' | translate }}" cellTemplate="received"
                    [width]="90" />
        <dxi-column name="actionButtons" dataField="id"
                    caption="{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.ACTION_BUTTONS' | translate }}" cellTemplate="actionButtons" />

        <ng-container *dxTemplate="let productCategory of 'productCategory'">
            {{ productCategory.value | productCategoryTranslation }}
        </ng-container>

        <ng-container *dxTemplate="let row of 'ringDiameter'">
            <div>{{ row.data.recipeData.ringDiameter }} {{ row.data.recipeData.ringDiameterUnit | unitOfMeasure }}</div>
        </ng-container>

        <ng-container *dxTemplate="let row of 'ringThicknessTarget'">
            <div *ngFor="let thickness of getTargetThicknessesForRow(row); let i = index">
                <div [ngClass]="showValueAsText(row.data.id) ? 'text-thickness' : 'input-thickness'">
                    <div *ngIf="isAbstimmringColumn(row); else noAbstimmring">
                        <span *ngIf="isDelta(row.data)">&#916;</span>
                        {{ thickness | number : '1.4-999999' }} {{ getTargetThicknessesUnitForRow(row) | unitOfMeasure }}
                    </div>
                    <ng-template #noAbstimmring>
                        <span *ngIf="isDelta(row.data)">&#916;</span>
                        {{ thickness }} {{ getTargetThicknessesUnitForRow(row) | unitOfMeasure }}
                    </ng-template>
                </div>
            </div>
        </ng-container>

        <ng-container *dxTemplate="let row of 'ringThicknessActual'">
            <div *ngIf="showValueAsText(row.data.id); else editValues">
                <div class="actual-values">
                    <div *ngFor="let thickness of getTargetThicknessesForRow(row); let i = index">
                        <div *ngIf="isNumber(getActualThicknessesForRow(row, i)); else noValue" class="target-thickness">
                            <div *ngIf="isAbstimmringColumn(row); else noAbstimmring">
                                {{ getActualThicknessesForRow(row, i) | number : '1.4-999999' }}
                                {{ row.data.recipeData.ringDiameterUnit | unitOfMeasure }}
                            </div>
                            <ng-template #noAbstimmring>
                                {{ getActualThicknessesForRow(row, i) }} {{ row.data.recipeData.ringDiameterUnit | unitOfMeasure }}
                            </ng-template>
                        </div>
                        <ng-template #noValue>
                            <div class="target-thickness">
                                <!-- Use this to enforce space -->
                                &nbsp;
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <ng-template #editValues>
                <div *ngFor="let _ of getTargetThicknessesForRow(row); let i = index" class="ring-thickness">
                    <div class="actual-ring-depth-field">
                        <div class="field-container">
                            <div class="input-field">
                                <zui-textfield
                                    name="number"
                                    step="0.1"
                                    input-type="number"
                                    align-right="true"
                                    [(ngModel)]="actualRingThicknesses[i]"
                                    ngDefaultControl
                                    [disabled]="!this.canChangeRingThicknesses"
                                ></zui-textfield>
                                {{ row.data.recipeData.ringDiameterUnit | unitOfMeasure }}
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *dxTemplate="let order of 'deliveryDate'">
            <div *ngIf="showValueAsText(order.value); else showSelect">{{ getDateString(order.data, true) }}</div>
            <ng-template #showSelect>
                <div class="form" [formGroup]="deliveryDateChangeForm">
                    <dx-date-box
                        type="date"
                        stylingMode="underlined"
                        formControlName="deliveryDate"
                        placeholder="DD.MM.YYYY"
                        displayFormat="dd.MM.yyyy"
                    >
                    </dx-date-box>
                    <dx-date-box type="time" stylingMode="underlined" formControlName="deliveryTime" placeholder="HH:MM" displayFormat="HH:mm">
                    </dx-date-box>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *dxTemplate="let order of 'predictedDeliveryDate'">
            <div *ngIf="showValueAsText(order.value); else showSelect" [ngClass]="{'delayed-delivery': hasDelayedDelivery(order.data)}">
                {{ getDateString(order.data, false) }}
            </div>
            <ng-template #showSelect>
                <div class="form" [formGroup]="predictedDeliveryDateChangeForm">
                    <dx-date-box
                        type="date"
                        stylingMode="underlined"
                        formControlName="predictedDeliveryDate"
                        placeholder="DD.MM.YYYY"
                        displayFormat="dd.MM.yyyy"
                    >
                    </dx-date-box>
                    <dx-date-box
                        type="time"
                        stylingMode="underlined"
                        formControlName="predictedDeliveryTime"
                        placeholder="HH:MM"
                        displayFormat="HH:mm"
                    >
                    </dx-date-box>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *dxTemplate="let order of 'state'">
            <div *ngIf="showValueAsText(order.value) || isStateShippedOrRejectedAndNoAdmin(order.data); else showSelect">
                {{ getStateString(order.data) }}
            </div>
            <ng-template #showSelect>
                <div class="input-field" [formGroup]="orderChangeForm">
                    <zui-select ngDefaultControl formControlName="state"
                        placeholder="{{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.PLACEHOLDER' | translate }}">
                        <zui-select-option value="Ordered">
                            {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.VALUES.ORDERED' | translate }}
                        </zui-select-option>
                        <zui-select-option value="InProduction">
                            {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.VALUES.IN_PRODUCTION' | translate }}
                        </zui-select-option>
                        <zui-select-option value="Shipped">
                            {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.VALUES.SHIPPED' | translate }}
                        </zui-select-option>
                        <zui-select-option [disabled]="!this.isAdmin" value="Rejected">
                            {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.STATE.VALUES.REJECTED' | translate }}
                        </zui-select-option>
                    </zui-select>
                </div>
            </ng-template>
        </ng-container>

        <ng-container *dxTemplate="let needsPickup of 'needsPickup'">
            <div *ngIf="needsPickup.value; else noPickUpNeeded">{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.NEEDSPICKUP_OPTIONS.YES' | translate }}</div>
            <ng-template #noPickUpNeeded>{{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.NEEDSPICKUP_OPTIONS.NO' | translate }}</ng-template>
        </ng-container>

        <ng-container *dxTemplate="let ordererDepartment of 'ordererDepartment'">
            <div [class.highlighted-orderer-department]="shouldOrdererDepartmentBeHighlighted(ordererDepartment.value)">
                {{ ordererDepartment.value }}
            </div>
        </ng-container>

        <ng-container *dxTemplate="let order of 'received'">
            <input
                class="received-checkbox"
                type="checkbox"
                [checked]="isRingReceived(order.data)"
                *ngIf="order.value !== this.toggleRingReceivedForOrderId; else ringReceivedWasSet"
                [disabled]="!canChangeRingReceived" 
                (click)="toggleRingReceived(order.data, $event)"/>
            <ng-template #ringReceivedWasSet>
                <zui-progress-ring slot="icon" size="s24" emphasis="highlight" mode="activity"></zui-progress-ring>
            </ng-template>
        </ng-container>

        <ng-container *dxTemplate="let order of 'actionButtons'">
            <div class="action-buttons">
                <div *ngIf="this.canSendComplaint">
                    <zui-icon-alerts-ewi-warning-round slot="icon" size="s24" class="complaint-button action-button"
                        (click)="openComplaintDialog(order.value)"
                        *ngIf="order.value !== this.complainedOrderId; else complained"></zui-icon-alerts-ewi-warning-round>
                    <ng-template #complained>
                        <dx-load-indicator id="complained-order-small-indicator" height="24" width="24"></dx-load-indicator>
                    </ng-template>
                </div>
                <div>
                    <zui-icon-common-edit-edit
                        slot="icon"
                        size="s24"
                        class="action-button"
                        (click)="enableInputs(order.data)"
                        *ngIf="showValueAsText(order.value) && (this.canChangeRingThicknesses || this.canChangeState || this.canChangeDeliveryDate)"
                    ></zui-icon-common-edit-edit>
                </div>
                <div>
                    <zui-icon-common-save
                        slot="icon"
                        size="s24"
                        class="action-button"
                        (click)="saveChanges(order.value)"
                        *ngIf="!showValueAsText(order.value) && (this.canChangeRingThicknesses || this.canChangeState || this.canChangeDeliveryDate)"
                    ></zui-icon-common-save>
                </div>
                <div>
                    <zui-icon-common-delete-one-item
                        slot="icon"
                        size="s24"
                        class="action-button"
                        (click)="disableInputs()"
                        *ngIf="!showValueAsText(order.value)"
                    >
                    </zui-icon-common-delete-one-item>
                </div>
                <div *ngIf="this.canToggleOversizedFlag" [id]="'oversized-tooltip-' + order.value">
                    <span class="material-symbols-outlined action-button"
                        [ngClass]="{'oversized-flag': isOversized(order.data)}" (click)="toggleOversize(order.data, order.value)"
                        *ngIf="this.toggledOversizeOrderId !== order.value; else toggled">
                        flag
                    </span>
                    <ng-template #toggled>
                        <dx-load-indicator id="oversize-flag-small-indicator" height="24" width="24"></dx-load-indicator>
                    </ng-template>
                </div>
                <div *ngIf="this.canToggleRawMaterialMissingFlag" [id]="'raw-material-missing-tooltip-' + order.value">
                    <span class="material-symbols-outlined action-button"
                        [ngClass]="{'raw-material-missing-flag': rawMaterialMissing(order.data)}" (click)="toggleRawMaterialMissing(order.data, order.value)"
                        *ngIf="this.toggledRawMaterialMissingOrderId !== order.value; else toggledRawMaterialMissing">
                        help
                    </span>
                    <ng-template #toggledRawMaterialMissing>
                        <dx-load-indicator id="raw-material-small-indicator" height="24" width="24"></dx-load-indicator>
                    </ng-template>
                </div>
                <div *ngIf="isOversized(order.data) && !this.canToggleOversizedFlag" [id]="'oversized-tooltip-' + order.value">
                    <span class="material-symbols-outlined oversized-flag">
                        flag
                    </span>
                </div>
                <div *ngIf="rawMaterialMissing(order.data) && !this.canToggleRawMaterialMissingFlag" [id]="'raw-material-missing-tooltip-' + order.value">
                    <span class="material-symbols-outlined raw-material-missing-flag">
                        help
                    </span>
                </div>
                <dx-tooltip [target]="'#oversized-tooltip-' + order.value" showEvent="mouseenter" hideEvent="mouseleave" [hideOnOutsideClick]="false">
                    <div>
                        {{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.TOOLTIPS.OVERSIZED' | translate }}
                    </div>
                </dx-tooltip>
                <dx-tooltip
                    [target]="'#raw-material-missing-tooltip-' + order.value"
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    [hideOnOutsideClick]="false"
                >
                    <div>
                        {{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.TOOLTIPS.RAW_MATERIAL_MISSING' | translate }}
                    </div>
                </dx-tooltip>
            </div>
        </ng-container>
    </dx-data-grid>
</div>

<dx-popup
    id="bulk-edit"
    [(visible)]="isBulkEditPopupVisible"
    [hideOnOutsideClick]="true"
    [showTitle]="true"
    [dragEnabled]="true"
    [title]="'ORDER_BULK_EDIT.POPUP.TITLE' | translate"
    [height]="550"
    [width]="1050"
    [resizeEnabled]="false"
    (onHiding)="closeBulkEdit(false)"
>
    <app-order-bulk-edit [setOrders]="selectedSearchResultItems" (closePopup)="closeBulkEdit($event)" />
</dx-popup>

<dx-popup
    id="details-popup"
    [(visible)]="isOrderDetailsViewVisible"
    [hideOnOutsideClick]="false"
    [showTitle]="true"
    [title]="'ORDER_DETAIL.POPUP_TITLE' | translate"
    [height]="'95%'"
    [width]="'95%'"
    [resizeEnabled]="false"
    (onHiding)="closeDetailsView()"
    [dragEnabled]="false"
>
    <app-order-detail [orderId]="orderIdForDetailsView" (closeDetailsView)="closeDetailsView()" />
</dx-popup>
