import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadOptions } from 'devextreme/data';
import { lastValueFrom, Observable, take } from 'rxjs';
import {
    ComplaintRequest,
    ComplaintResponse,
    GridLoadOrderResult,
    MeasurementData,
    MeasurementDocument,
    Order,
    OrderDeliveryDateBulkRequestCommit,
    OrderDeliveryDateBulkRequestDryRun,
    OrderDeliveryDateBulkResponse,
    OrderDetails,
} from 'src/app/common/models/order.type';
import { ENVIRONMENT } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class OrderHttpService {
    constructor(private http: HttpClient) {}

    /**
     * getOrders
     *
     * @param { LoadOptions } loadOptions filter object to pre-select orders
     * @returns {Observable<Order[]>} a observable that Emits a Array of all Orders
     */
    public async getOrders(loadOptions: LoadOptions): Promise<GridLoadOrderResult> {
        let params: HttpParams = new HttpParams();
        ['parentIds', 'filter', 'sort', 'take', 'skip', 'requireTotalCount'].forEach((i: string) => {
            if (
                i in loadOptions &&
                loadOptions[i as keyof LoadOptions] !== undefined &&
                loadOptions[i as keyof LoadOptions] !== null &&
                loadOptions[i as keyof LoadOptions] !== ''
            ) {
                params = params.set(i, JSON.stringify(loadOptions[i as keyof LoadOptions]));
            }
        });
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order`;

        return await toPromise(this.http.get<GridLoadOrderResult>(url, { params: params }));
    }

    /**
     * Gets a specific order
     *
     * @param { string } orderId of the order which should be loaded
     * @returns {Observable<Order>} the fetched order
     */
    public async getOrder(orderId: string): Promise<Order> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}`;

        return await toPromise(this.http.get<Order>(url));
    }

    /**
     * Get measurement documents from an order
     *
     * @param { string } orderId from the order from which the documents should be loaded
     * @returns {Observable<MeasurementDocument[]>} a observable that Emits a Array of all MeasurementDocuments for the specified orderId
     */
    public async getMeasurementDocuments(orderId: string): Promise<MeasurementDocument[]> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement`;

        return await toPromise(this.http.get<MeasurementDocument[]>(url));
    }

    /**
     * Upload measurement document for specified order
     *
     * @param { File } file from document which should be uploaded
     * @param { string } orderId from the order from which the document should be uploaded
     * @returns { Observable<HttpResponse<number>> } a observable that Emits the status code
     */
    public async uploadFile(file: File, orderId: string): Promise<number> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement`;

        return await toPromise(this.http.post<number>(url, formData));
    }

    /**
     * Get blob of the desired file
     *
     * @param { string } orderId from the order
     * @param { string } fileIdentifier which identifies the file
     * @returns { Observable<Blob> } blob of the desired file
     */
    public async getFile(orderId: string, fileIdentifier: string): Promise<Blob> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement/${fileIdentifier}`;

        return await toPromise(this.http.get(url, { responseType: 'blob' }));
    }

    /**
     * Upload measurement document for specified order
     *
     * @param { string } orderId from the order from which the document should be deleted
     * @param { string } fileIdentifier identifies the file which should be deleted
     * @returns { Observable<HttpResponse<number>> } a observable that Emits the status code
     */
    public async deleteFile(orderId: string, fileIdentifier: string): Promise<number> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurement/${fileIdentifier}`;

        return await toPromise(this.http.delete<number>(url));
    }

    /**
     *  Updates the details of a specific order
     *
     * @param { string } orderId of the order whose details are updated
     * @param { OrderDetails } orderDetails that should be updated
     * @returns { Observable<HttpResponse<number>> } an observable that Emits the status code
     */
    public async putOrderDetails(orderId: string, orderDetails: OrderDetails): Promise<number> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/details`;

        return await toPromise(this.http.put<number>(url, orderDetails));
    }

    /**
     *  TODO: Put to update selected Order
     *
     * @param { string } orderId from the order
     * @param { MeasurementData } ringThicknesses new ringThicknesses that should be updated
     * @returns {Observable<Order>} a observable that Emits a Array of all Orders
     */
    public async putActualRingThicknesses(orderId: string, ringThicknesses: MeasurementData): Promise<MeasurementData> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/measurements`;

        return await toPromise(this.http.put<MeasurementData>(url, ringThicknesses));
    }

    public async getDeliveryNote(orderId: string): Promise<Blob> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/delivery-note`;

        return await toPromise(this.http.get(url, { responseType: 'blob' }));
    }

    public async putComplaint(orderId: string, complaintRequest: ComplaintRequest): Promise<ComplaintResponse> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/complain`;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return await toPromise(this.http.put<ComplaintResponse>(url, complaintRequest, { headers }));
    }

    public async putOversize(orderId: string): Promise<Order> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/oversize`;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return await toPromise(this.http.put<Order>(url, null, { headers }));
    }

    public async toggleRawMaterialMissing(orderId: string): Promise<Order> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/raw-material-missing`;

        const headers: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });

        return await toPromise(this.http.put<Order>(url, null, { headers }));
    }

    public async patchRingReceived(orderId: string, ringReceived: boolean): Promise<number> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/${orderId}/received/${ringReceived}`;

        return await toPromise(this.http.patch<number>(url, null));
    }

    public putDeliveryDatesDryRun(orderDeliveryDateBulkRequestDryRun: OrderDeliveryDateBulkRequestDryRun): Observable<OrderDeliveryDateBulkResponse> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/bulk/delivery-date/dry-run`;

        return this.http.put<OrderDeliveryDateBulkResponse>(url, orderDeliveryDateBulkRequestDryRun);
    }

    public putDeliveryDatesCommit(orderDeliveryDateBulkRequestCommit: OrderDeliveryDateBulkRequestCommit): Observable<OrderDeliveryDateBulkResponse> {
        const url: string = `${ENVIRONMENT.ressourceEndpoint}order/bulk/delivery-date/commit`;

        return this.http.put<OrderDeliveryDateBulkResponse>(url, orderDeliveryDateBulkRequestCommit);
    }
}

// eslint-disable-next-line jsdoc/require-jsdoc
async function toPromise<T>(observable: Observable<T>): Promise<T> {
    const request$: Observable<T> = observable.pipe(take(1));

    return await lastValueFrom<T>(request$);
}
