import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment';
import { User } from '../models/user.type';
import { PdxSupplier } from "../models/order.type";

@Injectable({
    providedIn: 'root',
})
export class AuthHttpService {
    constructor(private http: HttpClient) {}

    /**
     * Login User
     *
     * @returns {Observable<User>} the logged in user with userinformation
     */
    public loginUser(): Observable<User> {
        return this.http.put<User>(ENVIRONMENT.ressourceEndpoint + `accesscontrol/users/login`, null);
    }

    /**
     * Login User
     *
     * @returns {Observable<PdxSupplier[]>} a list of allowed PDX Suppliers
     */
    public getPdxSuppliers(): Observable<PdxSupplier[]> {
        return this.http.get<PdxSupplier[]>(ENVIRONMENT.ressourceEndpoint + `accesscontrol/users/suppliers`);
    }
}
