import { Injectable } from '@angular/core';
import { OrderStore } from '../../store/order/order.store';
import {
    BulkEditOrder,
    ComplaintRequest,
    ComplaintResponse,
    GridLoadOrderResult,
    MeasurementData,
    MeasurementDocument,
    Order,
    OrderDeliveryDateBulkRequestCommit,
    OrderDeliveryDateBulkRequestDryRun,
    OrderDeliveryDateBulkResponse,
    OrderDetails,
    OrderIdWithDeliveryDate,
    OrderSearch,
} from 'src/app/common/models/order.type';
import { OrderHttpService } from './order-http.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { HttpHeaderResponse } from '@angular/common/http';
import { catchError, map, Observable } from 'rxjs';
import { LoadOptions } from 'devextreme/data';
import { OrderState } from '../../../common/models/orderState.type';
import { applySearchQuery } from './applySearchQuery';

@Injectable({
    providedIn: 'root',
})
export class OrderService {
    constructor(readonly orderStore: OrderStore, private orderHttpService: OrderHttpService, private toastService: ToastService) {}

    public async fetchOrderResults(searchQuery: OrderSearch | undefined, loadOptions: LoadOptions): Promise<OrderState> {
        this.orderStore.setLoading(true);
        const filter: LoadOptions = applySearchQuery(searchQuery, loadOptions);

        try {
            const result: GridLoadOrderResult = await this.orderHttpService.getOrders(filter);

            const newOrderList: Order[] = result.data?.map((order: Order) => {
                return {
                    ...order,
                    desiredDeliveryDate: new Date(order.desiredDeliveryDate + '.000Z'),
                    orderDate: new Date(order.orderDate + '.000Z'),
                    details: {
                        ...order.details,
                        predictedDeliveryDate: order.details.predictedDeliveryDate
                            ? new Date(order.details.predictedDeliveryDate + '.000Z')
                            : undefined,
                    },
                };
            });

            const state: OrderState = {
                orderResult: {
                    ...result,
                    data: newOrderList,
                },
                searchQuery: searchQuery,
                detailOrder: undefined,
                measurementDocuments: [],
            };

            this.orderStore.reset();
            this.orderStore.update(state);
            this.orderStore.setLoading(false);

            return state;
        } catch (error) {
            const httpError: HttpHeaderResponse = error as HttpHeaderResponse;

            this.toastService.emitError({
                code: httpError.status,
                message: 'ORDER_OVERVIEW.COULD_NOT_LOAD_ORDERS',
            });
            this.orderStore.reset();

            throw error;
        } finally {
            this.orderStore.setLoading(false);
        }
    }

    public async fetchMeasurementDocuments(orderId: string): Promise<MeasurementDocument[]> {
        this.orderStore.setLoading(true);

        try {
            const documents: MeasurementDocument[] = await this.orderHttpService.getMeasurementDocuments(orderId);

            this.orderStore.update({
                measurementDocuments: documents,
            });

            return documents;
        } catch (error) {
            const httpError: HttpHeaderResponse = error as HttpHeaderResponse;

            this.toastService.emitError({
                code: httpError.status,
                message: 'ORDER_DETAILS.COULD_NOT_LOAD_MEASURMENT_DOCUMENTS',
            });
            this.orderStore.reset();
            throw error;
        } finally {
            this.orderStore.setLoading(false);
        }
    }

    public uploadFile(file: File, orderId: string): Promise<number> {
        return this.orderHttpService.uploadFile(file, orderId);
    }

    public getFile(orderId: string, fileIdentifier: string): Promise<Blob> {
        return this.orderHttpService.getFile(orderId, fileIdentifier);
    }

    public deleteFile(orderId: string, fileIdentifier: string): Promise<number> {
        return this.orderHttpService.deleteFile(orderId, fileIdentifier);
    }

    public updateActualRingThicknesses(orderId: string, ringThicknesses: MeasurementData): void {
        this.orderHttpService.putActualRingThicknesses(orderId, ringThicknesses).then(async () => {
            await this.setDetailOrder(orderId);
            this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
        });
    }

    public updateActualRingThicknessesInDataGrid(orderId: string, ringThicknesses: MeasurementData): Promise<MeasurementData> {
        return this.orderHttpService.putActualRingThicknesses(orderId, ringThicknesses);
    }

    public selectedOrder(selectedOrderId: string): void {
        this.setDetailOrder(selectedOrderId);
    }

    public async updateOrderDetails(orderId: string, orderDetails: OrderDetails): Promise<void> {
        this.orderStore.setLoading(true);

        try {
            await this.orderHttpService.putOrderDetails(orderId, orderDetails);
            await this.setDetailOrder(orderId);

            this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
        } catch (error) {
            const httpError: HttpHeaderResponse = error as HttpHeaderResponse;

            if (httpError.status === 409) {
                this.toastService.emitError({
                    code: httpError.status,
                    message: 'ORDER_OVERVIEW.NOT_ALL_RECORDS_HAVE_BEEN_RECORDED',
                });
            } else {
                this.toastService.emitError({
                    code: httpError.status,
                    message: 'ORDER_OVERVIEW.COULD_NOT_SUBMIT_ORDER_CHANGES',
                });
            }

            throw error;
        } finally {
            this.orderStore.setLoading(false);
        }
    }

    public getDeliveryNote(orderId: string): void {
        this.orderHttpService.getDeliveryNote(orderId).then((response: Blob) => {
            const url: string = URL.createObjectURL(response);
            window.open(url);
        });
    }

    public sendComplaint(orderId: string, newThicknesses: number[] | null, setOrderStateRejected: boolean): Promise<ComplaintResponse> {
        return this.orderHttpService.putComplaint(orderId, {
            newRingThicknessTarget: newThicknesses,
            setOrderStateRejected: setOrderStateRejected,
        } as ComplaintRequest);
    }

    public toggleOversize(orderId: string): Promise<Order> {
        return this.orderHttpService.putOversize(orderId);
    }

    public toggleRawMaterialMissing(orderId: string): Promise<Order> {
        return this.orderHttpService.toggleRawMaterialMissing(orderId);
    }

    public sendRingReceived(orderId: string, ringReceived: boolean): Promise<number> {
        return this.orderHttpService.patchRingReceived(orderId, ringReceived);
    }

    private async setDetailOrder(orderId: string): Promise<void> {
        try {
            const order: Order = await this.orderHttpService.getOrder(orderId);

            if (!order) {
                this.orderStore.setError(`Could not find Order by OrderId ${orderId}`);
                return;
            }

            order.details.predictedDeliveryDate = order.details.predictedDeliveryDate
                ? new Date(order.details.predictedDeliveryDate + '.000Z')
                : undefined;

            order.desiredDeliveryDate = new Date(order.desiredDeliveryDate + '.000Z');
            order.orderDate = new Date(order.orderDate + '.000Z');

            this.orderStore.update({
                detailOrder: order,
            });
        } catch (error) {
            const httpError: HttpHeaderResponse = error as HttpHeaderResponse;

            this.toastService.emitError({
                code: httpError.status,
                message: 'ORDER_DETAILS.MISSING_ORDERID',
            });
            this.orderStore.reset();
        } finally {
            this.orderStore.setLoading(false);
        }
    }

    public deliveryDatesDryRun(deliveryDate: Date, orderIds: string[]): Observable<OrderDeliveryDateBulkResponse> {
        const orderDeliveryDateBulkRequestDryRun: OrderDeliveryDateBulkRequestDryRun = { deliveryDate: deliveryDate, orderIds: orderIds };

        return this.orderHttpService.putDeliveryDatesDryRun(orderDeliveryDateBulkRequestDryRun).pipe(
            map((response: OrderDeliveryDateBulkResponse) => {
                const result: OrderDeliveryDateBulkResponse = response;

                result.orders = response.orders.map((order: BulkEditOrder) => {
                    return {
                        ...order,
                        orderId: order.orderId,
                        deliveryDate: new Date(order.deliveryDate.toString().substring(0, 19) + '.000Z'),
                        newDeliveryDate: new Date(order.newDeliveryDate.toString().substring(0, 19) + '.000Z'),
                    };
                });
                return result;
            }),
            catchError((error: HttpHeaderResponse) => {
                throw error;
            })
        );
    }

    public deliveryDatesCommit(
        orderIds: string[],
        orderIdWithDeliveryDate: OrderIdWithDeliveryDate[],
        deliveryDate: Date
    ): Observable<OrderDeliveryDateBulkResponse> {
        const orderDeliveryDateBulkRequestCommit: OrderDeliveryDateBulkRequestCommit = {
            orderIds: orderIds,
            orders: orderIdWithDeliveryDate,
            deliveryDate: deliveryDate,
        };

        return this.orderHttpService.putDeliveryDatesCommit(orderDeliveryDateBulkRequestCommit).pipe(
            map((response: OrderDeliveryDateBulkResponse) => {
                if (response.state === 2) this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
                response.orders = response.orders.map((order: BulkEditOrder) => {
                    return {
                        ...order,
                        deliveryDate: new Date(order.deliveryDate.toString().substring(0, 19) + '.000Z'),
                        newDeliveryDate: new Date(order.newDeliveryDate.toString().substring(0, 19) + '.000Z'),
                    };
                });
                return response;
            }),
            catchError((error: HttpHeaderResponse) => {
                this.toastService.emitError({
                    code: error.status,
                    message: 'ORDER_OVERVIEW.COULD_NOT_SUBMIT_ORDER_CHANGES',
                });

                throw error;
            })
        );
    }

    public executeIncomingGoodsBookingRequest(orderId: string): Observable<number> {
        return this.orderHttpService.postExecuteIncomingGoodsBookingRequest(orderId);
    }
}
