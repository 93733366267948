<div [formGroup]="searchForm" class="form-container">
    <div class="left-column">
        <div class="search-field-date" formGroupName="desiredDeliveryDate">
            <div class="input-field-label">
                <label for="desiredDeliveryDate">{{ 'ORDERS_OVERVIEW.FORM.DESIRED_DELIVERY_DATE.LABEL' | translate }}</label>
            </div>
            <div class="input-field-position">
                <dx-date-box formControlName="from" type="date" stylingMode="underlined" placeholder="DD.MM.YYYY" displayFormat="dd.MM.yyyy">
                </dx-date-box>

                <dx-date-box formControlName="to" type="date" stylingMode="underlined" placeholder="DD.MM.YYYY" displayFormat="dd.MM.yyyy">
                </dx-date-box>
            </div>
        </div>

        <div class="search-field">
            <div class="input-field-label">
                <label for="productCategories">{{ 'ORDERS_OVERVIEW.FORM.PRODUCT_CATEGORY.LABEL' | translate }}</label>
            </div>
            <div class="input-field-position">
                <dx-tag-box
                    formControlName="productCategories"
                    name="productCategories"
                    [items]="productCategories"
                    [showSelectionControls]="true"
                    placeholder="{{ 'ORDERS_OVERVIEW.FORM.PRODUCT_CATEGORY.PLACEHOLDER' | translate }}"
                    [showDropDownButton]="true"
                >
                </dx-tag-box>
            </div>
        </div>

        <div class="search-field">
            <div class="input-field-label">
                <label for="ordererDepartments">{{ 'ORDERS_OVERVIEW.FORM.ORDERER_DEPARTMENT.LABEL' | translate }}</label>
            </div>
            <div class="input-field-position">
                <dx-tag-box
                    formControlName="ordererDepartments"
                    name="ordererDepartments"
                    [items]="ordererDepartments"
                    [showSelectionControls]="true"
                    placeholder="{{ 'ORDERS_OVERVIEW.FORM.ORDERER_DEPARTMENT.PLACEHOLDER' | translate }}"
                    [showDropDownButton]="true"
                >
                </dx-tag-box>
            </div>
        </div>
    </div>

    <div class="right-column">
        <div class="search-field">
            <div class="input-field-label">
                <label for="states">{{ 'ORDERS_OVERVIEW.FORM.STATE.LABEL' | translate }}</label>
            </div>
            <div class="input-field-position">
                <dx-tag-box
                    formControlName="states"
                    name="states"
                    [dataSource]="orderStates"
                    displayExpr="displayName"
                    valueExpr="value"
                    [showSelectionControls]="true"
                    placeholder="{{ 'ORDERS_OVERVIEW.FORM.STATE.PLACEHOLDER' | translate }}"
                    itemTemplate="itemTemplate"
                    tagTemplate="tagTemplate"
                    [showDropDownButton]="true"
                >
                    <div *dxTemplate="let itemData of 'itemTemplate'">
                        {{ itemData.displayName | translate }}
                    </div>
                    <div *dxTemplate="let itemData of 'tagTemplate'">
                        <div class="dx-tag-content">
                            <span>{{ itemData.displayName | translate }}</span>
                            <div class="dx-tag-remove-button"></div>
                        </div>
                    </div>
                </dx-tag-box>
            </div>
        </div>

        <div class="search-field">
            <div class="input-field-label">
                <label for="set">{{ 'ORDERS_OVERVIEW.FORM.SET.LABEL' | translate }}</label>
            </div>
            <div class="input-field-position">
                <dx-text-box formControlName="set" name="set" placeholder="{{ 'ORDERS_OVERVIEW.FORM.SET.PLACEHOLDER' | translate }}"></dx-text-box>
            </div>
        </div>

        <div class="search-field">
            <div class="input-field-label">
                <label for="complainedOrders">{{ 'ORDERS_OVERVIEW.FORM.COMPLAINED_ORDERS.LABEL' | translate }}</label>
            </div>
            <div class="input-field-position">
                <dx-check-box formControlName="complainedOrders" name="complainedOrders"></dx-check-box>
            </div>
        </div>

        <div class="button-container">
            <dx-button (click)="clearAllFormControls()" class="clear-button" text="{{ 'ORDERS_OVERVIEW.CLEAR_BUTTON' | translate }}"> </dx-button>
            <dx-button
                [disabled]="disableSearchButton"
                (click)="submitSearch()"
                class="submit-button"
                text="{{ 'ORDERS_OVERVIEW.SEARCH_BUTTON' | translate }}"
            >
            </dx-button>
        </div>
    </div>
</div>

<dx-popup
    [(visible)]="isRefreshSearchDialogVisible"
    [hideOnOutsideClick]="true"
    [showTitle]="true"
    [dragEnabled]="true"
    [title]="'REFRESH_SEARCH_DIALOG.TITLE' | translate"
    [height]="250"
    [width]="420"
    [resizeEnabled]="false"
>
    <app-refresh-search-dialog (closeDialog)="closeRefreshSearchDialog($event)"></app-refresh-search-dialog>
</dx-popup>
