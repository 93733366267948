<div class="dialog">
    <div *ngIf="errorOrDetails === 'error'">
        <b class="header-text">{{
            'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.DIALOG_TEXT_ERROR' |
            translate }}</b>
        <ul class="error-list">
            <li *ngFor="let error of errors" class="error-item">
                <div class="error-container">
                    <i *ngIf="error.messageType === ERROR_MESSAGETYPE.Warning" class="dx-icon-warning"></i>
                    <i *ngIf="error.messageType === ERROR_MESSAGETYPE.Error" class="dx-icon-clear"></i>
                    {{ error.message }}
                </div>
            </li>
        </ul>
    </div>
    <div *ngIf="errorOrDetails === 'details'">
        <div>
            <b>{{
                'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.DETAILS_LABEL_FROM'
                | translate }}: </b>{{ details?.name }}
        </div>
        <div>
            <b>{{
                'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.DETAILS_LABEL_AT'
                | translate }}: </b>{{ details?.timeStamp }}
        </div>
        <div>
            <b>{{
                'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.DETAILS_LABEL_MATERIALRECEIPT'
                | translate }}: </b>{{ details?.materialReceipt }}
        </div>
        <div>
            <b>{{
                'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.DETAILS_LABEL_MATERIALRECEIPTPOSITION'
                | translate }}: </b>{{ details?.materialReceiptPosition }}
        </div>
        <div>
            <b>{{
                'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.DETAILS_LABEL_MATERIALRECEIPTYEAR'
                | translate }}: </b>{{ details?.materialReceiptYear }}
        </div>
    </div>
    <div class="buttons">
        <dx-button (click)="closeDialog.emit()" class="submit-button"
            [text]="'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.CLOSE_BUTTON' | translate">
        </dx-button>
    </div>
</div>