import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { OrderState } from '../../../common/models/orderState.type';

/**
 * Create the initial OrderStore state
 * 
 * @returns {OrderState} initial State
 */
export function createInitialOrderState(): OrderState{
    return {
        orderResult: { data: [], totalCount: 0, groupCount: 0, summary: null },
        searchQuery: undefined,
        detailOrder: undefined,
        measurementDocuments: [] 
    };
}

@StoreConfig({name: 'orderStore', resettable: true})
@Injectable({ providedIn: 'root' })
export class OrderStore extends Store<OrderState> {
    constructor() {
        super(createInitialOrderState());
    }
}