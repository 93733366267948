import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ENVIRONMENT } from 'src/environments/environment';
import { AuthService } from '../../../services/auth.service';
import { AuthStore } from 'src/app/main/store/auth/auth.store';
import { User } from '../../../models/user.type';
import { AuthQuery } from "../../../../main/store/auth/auth.query";
import { AuthState } from "../../../models/authState.type";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-header-bar',
    templateUrl: './header-bar.component.html',
    styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {
    public subscriptions: Array<Subscription> = [];

    public versionNumber: string = '';

    public user!: User;

    public isUserInfoPopUpVisible: boolean = false;
    public isReleaseNotesPopUpVisible: boolean = false;

    constructor(
      private readonly authService: AuthService,
      translateService: TranslateService,
      readonly authStore: AuthStore,
      readonly authQuery: AuthQuery,) {
        this.authQuery.authState$.subscribe((state: AuthState) => {
            const lang = state.user.language.indexOf('en-') === 0
              ? 'en-GB'
              : state.user.language;

            translateService.use(lang);
            this.user = state.user;
        })
    }

    ngOnInit(): void {
        this.versionNumber = ENVIRONMENT.versionNumber;
    }

    public logout() {
        this.authStore.reset();
        this.authService.logout();
    }

    public openUserInfo(): void {
        if (Object.keys(this.user).length)
            this.isUserInfoPopUpVisible = true;
    }

    public closeUserInfo(): void {
        this.isUserInfoPopUpVisible = false;
    }

    public openReleaseNotes(): void {
        if (Object.keys(this.user).length)
            this.isReleaseNotesPopUpVisible = true;
    }

    public closeReleaseNotes(): void {
        this.isReleaseNotesPopUpVisible = false;
    }
}
