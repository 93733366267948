export interface IOrder {
    id: string;
    details: OrderDetails;
    productCategory: ProductCategory;
    ringReceived: boolean;
}

export interface Order extends IOrder {
    id: string;
    parentId: string;
    childId: string;
    orderNumber: string;
    materialNumber: string;
    ordererFirstname: string;
    ordererLastname: string;
    ordererDepartment: string;
    desiredDeliveryDate: Date;
    set: string;
    supplierId: string;
    objectiveType: string;
    orderPosition: string;
    sapProductionOrderNumber: string;
    recipeData: RecipeData;
    measurementData: MeasurementData;
    details: OrderDetails;
    productCategory: ProductCategory;
    needsPickup: boolean;
    recipePriority: number;
    ringReceived: boolean;
    orderDate: Date;
    isOrderOverSized: boolean;
    rawMaterialMissing: boolean;
    hasDelayedDelivery: boolean;
};

export type OrderSearch = {
    complainedOrders?: boolean;
    set?: string;
    ordererDepartments?: Department[];
    states?: string[];
    desiredDeliveryDate?: {
        from: Date;
        to: Date;
    };
    productCategories?: string[];
};

export type OrderDetails = {
    state?: OrderStates;
    priority?: number;
    predictedDeliveryDate?: Date;
    changeReason?: string;
    deliveryDate?: Date;
};

export enum OrderStates {
    'InProduction' = 1,
    'Shipped' = 2,
    'Ordered' = 3,
    'Rejected' = 4,
}

export enum Department {
    'Montage' = 0,
    'Justage' = 1,
    'Wetzlar' = 2,
    'OPK' = 3,
}

export enum ProductCategory {
    'None' = 0,
    'Abstimmring' = 1,
    'Erdteil' = 2,
    'Optikring' = 3,
    'TBO' = 4,
    'Rohling' = 5,
    'Target' = 6,
    'TLS' = 7,
}

export enum UnitOfMeasure {
    'None' = 0,
    'nm' = 1,
    'μm' = 2,
    'mm' = 3,
    'cm' = 4,
    'dm' = 5,
    'm' = 6,
}

export type RecipeData = {
    holeNumber: number;
    ringDiameter: number;
    ringDiameterUnit: UnitOfMeasure;
    ringThicknessTarget: number[];
    ringThicknessTargetUnit: UnitOfMeasure;
};

export type MeasurementData = {
    ringThicknessActual: number[];
};

export type MeasurementDocument = {
    fileIdentifier: string;
    fileName: string;
    contentType: string;
};

export type ComplaintResponse = {
    id: string;
};

export type ComplaintRequest = {
    newRingThicknessTarget: number[];
    setOrderStateRejected: boolean;
};

export type GridLoadOrderResult = {
    data: Order[];
    groupCount: number;
    summary: unknown;
    totalCount: number;
};

export type BulkEditOrder = {
    orderId: string;
    materialNumber: string;
    productCategory: ProductCategory;
    ordererDepartment: string;
    sapProductionOrderNumber: string;
    recipePriority: number;
    ringDiameter: number;
    ringDiameterUnit: number;
    set: string;
    deliveryDate: Date;
    newDeliveryDate: Date;
    additionallyChanged: boolean;
};

export type OrderDeliveryDateBulkResponse = {
    orders: BulkEditOrder[];
    state: number;
};

export type OrderDeliveryDateBulkRequestDryRun = {
    orderIds: string[];
    deliveryDate: Date;
};

export type OrderIdWithDeliveryDate = {
    orderId: string;
    deliveryDate: Date;
};

export type OrderDeliveryDateBulkRequestCommit = {
    orderIds: string[];
    orders: OrderIdWithDeliveryDate[];
    deliveryDate: Date;
};

export type OrderStateMapping = {
    value: string | OrderStates;
    displayName: string;
    disabled?: boolean;
};
