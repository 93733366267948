import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
    MeasurementDocument,
} from 'src/app/common/models/order.type';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { OrderDetailService } from '../../../services/order-detail.service';
import { OrderService } from 'src/app/main/services/order/order.service';
import { User } from 'src/app/common/models/user.type';
import { AuthQuery } from 'src/app/main/store/auth/auth.query';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import { DxListComponent } from 'devextreme-angular';

@Component({
    selector: 'app-measurement-card',
    templateUrl: './measurement-card.component.html',
    styleUrls: ['./measurement-card.component.scss'],
})
export class MeasurementCardComponent implements OnChanges {
    public measurementDocuments!: MeasurementDocument[];
    public files: NgxFileDropEntry[] = [];
    @ViewChild(DxListComponent, { static: false }) list!: DxListComponent;

    public user!: User;
    public canChangeDocuments: boolean = false;
    
    @Input()
    public orderId!: string;

    constructor(
        readonly orderDetailService: OrderDetailService,
        readonly orderService: OrderService,
        readonly authQuery: AuthQuery,
        private toastService: ToastService
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['orderId']) this.initView();
    }

    private initView(): void {
        this.fetchMeasurementDocuments();
        this.fetchPermissions();
    }

    public dropped(files: NgxFileDropEntry[]) {
        if(this.canChangeDocuments) {
            this.files = files;
            for (const droppedFile of files) {
                if (droppedFile.fileEntry.isFile) {
                    const fileEntry: FileSystemFileEntry =
                        droppedFile.fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        this.orderService
                            .uploadFile(file, this.orderId)
                            .then(() => {
                                this.fetchMeasurementDocuments();
                            });
                    });
                }
            }
        } else {
            this.toastService.emitError({
                message: 'ORDER_DETAILS.MISSING_PERMISSIONS_CHANGE_DOCUMENTS',
            });
        }

    }

    private fetchMeasurementDocuments(): void {
        this.orderService.fetchMeasurementDocuments(this.orderId);
        this.orderDetailService
            .fetchMeasurementDocuments()
            .subscribe(
                (measurementDocuments: MeasurementDocument[]) =>
                    (this.measurementDocuments = measurementDocuments)
            );
    }

    public openFile(document: MeasurementDocument): void {
        this.orderService
        .getFile(this.orderId, document.fileIdentifier)
        .then((response: Blob) => {
            const blob: Blob = new Blob([response], { type: document.contentType });
            const url: string = URL.createObjectURL(blob);
            window.open(url);
        });
    }

    public deleteFile(document: MeasurementDocument): void {
        if(this.canChangeDocuments){
            this.orderService
            .deleteFile(this.orderId, document.fileIdentifier)
            .then(() => {
                this.toastService.emitSuccess({message: 'DELETE'});
                this.fetchMeasurementDocuments();
            });
        } else {
            this.toastService.emitError({
                message: 'ORDER_DETAILS.MISSING_PERMISSIONS_CHANGE_DOCUMENTS',
            });
        }
    }

    private fetchPermissions(): void {
        this.authQuery.user$.subscribe({
            next: (user: User) => {
                this.user = user !== undefined ? user : {} as User;
                this.canChangeDocuments = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_MEASUREMENT_DATA') ? true : false;
            },
        });
    }
}
