import { Component } from '@angular/core';
import { User } from '../../../models/user.type';
import { AuthQuery } from "../../../../main/store/auth/auth.query";
import { AuthState } from "../../../models/authState.type";

@Component({
    selector: 'app-user-information',
    templateUrl: './user-information.component.html',
    styleUrls: ['./user-information.component.scss'],
})
export class UserInformationComponent {

    public user!: User;

    constructor(readonly authQuery: AuthQuery) {
        this.authQuery.authState$.subscribe((state: AuthState) => {
            this.user = state.user;
        })
    }
}
