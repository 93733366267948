import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorMessage, ErrorMessageType, IncomingGoodsBookingDetails } from 'src/app/common/models/order.type';

@Component({
    selector: 'app-booking-details-error-dialog',
    templateUrl: './booking-details-error-dialog.component.html',
    styleUrls: ['./booking-details-error-dialog.component.scss'],
})
export class BookingDetailsErrorDialogComponent {
    @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

    @Input() errorOrDetails!: 'error' | 'details';
    @Input() errors?: ErrorMessage[];
    @Input() details?: IncomingGoodsBookingDetails;

    public ERROR_MESSAGETYPE = ErrorMessageType;
}
