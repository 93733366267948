import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    GridLoadOrderResult,
    IncomingGoodsBookingRequestStatus,
    IOrder,
    MeasurementData,
    Order,
    OrderDetails,
    OrderSearch,
    OrderStates,
    ProductCategory,
    RecipeData,
    UnitOfMeasure,
} from 'src/app/common/models/order.type';
import { User } from 'src/app/common/models/user.type';
import { StateTranslationService } from 'src/app/common/services/state-translation-service/state-translation.service';
import { OrderService } from 'src/app/main/services/order/order.service';
import { AuthQuery } from 'src/app/main/store/auth/auth.query';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UtilService } from 'src/app/common/services/utils/utils.service';
import { ToastService } from 'src/app/common/services/toast/toast.service';
import dxDataGrid, { EditorPreparingEvent, SelectionChangedEvent } from 'devextreme/ui/data_grid';
import dxCheckBox, { InitializedEvent, ValueChangedEvent } from 'devextreme/ui/check_box';
import 'devextreme/integration/jquery';
import { Location } from '@angular/common';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data';
import { OrderStore } from '../../../../store/order/order.store';
import { OrderState } from 'src/app/common/models/orderState.type';

export interface OrderSearchResultItem extends IOrder {
    id: string;
    orderNumber: string;
    materialNumber: string;
    ordererFirstname: string;
    ordererLastname: string;
    ordererDepartment: string;
    desiredDeliveryDate: Date;
    predictedDeliveryDate?: Date;
    set: string;
    supplierId: string;
    objectiveType: string;
    details: OrderDetails;
    recipeData: RecipeData;
    measurementData?: MeasurementData;
    state: string;
    orderPosition: string;
    sapProductionOrderNumber: string;
    productCategory: ProductCategory;
    needsPickup: boolean;
    recipePriority: number;
    ringReceived: boolean;
    isOrderOverSized: boolean;
    rawMaterialMissing: boolean;
    hasDelayedDelivery: boolean;
}

type GridRow = {
    data: OrderSearchResultItem;
};

@Component({
    selector: 'app-order-data-grid',
    templateUrl: './order-data-grid.component.html',
    styleUrls: ['./order-data-grid.component.scss'],
})
export class OrderDataGridComponent implements OnInit, OnChanges {
    @Input() orderSearch!: OrderSearch;
    @Input() public orderIdForDetailsView: string | undefined;

    @Output() orderRowClick$: EventEmitter<Order> = new EventEmitter<Order>();

    selectAllCheckBox!: dxCheckBox;
    checkBoxUpdating = false;

    public datasource: CustomStore = {} as CustomStore;

    public INCOMING_GOODS_BOOKING_REQUEST_STATUS = IncomingGoodsBookingRequestStatus;

    public orderSearchResultsDataSource!: Array<OrderSearchResultItem>;
    public canSendComplaint: boolean = false;
    public canChangeRingThicknesses: boolean = false;
    public canChangeState: boolean = false;
    public canChangeDeliveryDate: boolean = false;
    public canChangePredictedDeliveryDate: boolean = false;
    public canChangeRingReceived: boolean = false;
    public canChangeStateToRejected: boolean = false;
    public canToggleOversizedFlag: boolean = false;
    public canToggleRawMaterialMissingFlag: boolean = false;
    public canExecuteIncomingGoodsBooking: boolean = false;

    public isAdmin: boolean = false;
    public user!: User;
    public complainedOrderId!: string;
    public showComplaintDialog: boolean = false;
    public orderToEdit: string | undefined;
    public actualRingThicknesses: string[] = [];
    public orderChangeForm!: FormGroup;
    public deliveryDateChangeForm!: FormGroup;
    public predictedDeliveryDateChangeForm!: FormGroup;
    public userLocale!: string;
    public showSelectionCheckboxes: boolean = false;
    public selectedSearchResultItems: OrderSearchResultItem[] = [];
    public isBulkEditPopupVisible: boolean = false;
    public isOrderDetailsViewVisible: boolean = false;

    public toggledOversizeOrderId: string | undefined;
    public toggledRawMaterialMissingOrderId: string | undefined;
    public toggleRingReceivedForOrderId: string | undefined;

    constructor(
        readonly stateTranslationService: StateTranslationService,
        readonly orderService: OrderService,
        readonly authQuery: AuthQuery,
        private formBuilder: FormBuilder,
        readonly datePipe: DatePipe,
        readonly utilService: UtilService,
        readonly toastService: ToastService,
        readonly orderStore: OrderStore,
        readonly location: Location
    ) {}

    public ngOnInit(): void {
        this.updateOrderSearchResultsDataSource();
        this.fetchPermissions();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['orderSearch']) {
            this.updateOrderSearchResultsDataSource();
        }
        const orderIdForDetailsView: string | undefined = changes['orderIdForDetailsView']
            ? changes['orderIdForDetailsView'].currentValue
            : undefined;

        if (orderIdForDetailsView && orderIdForDetailsView.length > 0) {
            this.isOrderDetailsViewVisible = true;
        } else {
            this.isOrderDetailsViewVisible = false;
        }
    }

    public handleComplaintDialogCloseEvent(orderId?: string) {
        this.showComplaintDialog = false;
        this.complainedOrderId = '';
        if (orderId) {
            this.updateOrderSearchResultsDataSource();
        }
    }

    public openComplaintDialog(orderId: string): void {
        if (this.canSendComplaint) {
            this.complainedOrderId = orderId;
            this.showComplaintDialog = true;
        }
    }

    // explicit any because DevExpress does not provide a DataType for the event
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onRowClickEventWitRowData(event: any): void {
        if (event.event.target.tagName === 'TD' || event.event.target.tagName === 'DIV') {
            this.orderIdForDetailsView = event.data.id;
            this.isOrderDetailsViewVisible = true;
            this.location.go(`orders/order/${event.data.id}`);
        }
    }

    public toggleOversize(order: OrderSearchResultItem, orderId: string): void {
        this.toggledOversizeOrderId = orderId;

        this.orderService
            .toggleOversize(orderId)
            .then((o: Order) => (order.isOrderOverSized = o.isOrderOverSized))
            .finally(() => (this.toggledOversizeOrderId = undefined));

        this.toggledOversizeOrderId = undefined;
    }

    public toggleRawMaterialMissing(order: OrderSearchResultItem, orderId: string): void {
        this.toggledRawMaterialMissingOrderId = orderId;

        this.orderService
            .toggleRawMaterialMissing(orderId)
            .then((o: Order) => (order.rawMaterialMissing = o.rawMaterialMissing))
            .finally(() => (this.toggledRawMaterialMissingOrderId = undefined));

        this.toggledRawMaterialMissingOrderId = undefined;
    }

    private fetchPermissions(): void {
        this.authQuery.user$.subscribe({
            next: (user: User) => {
                this.user = user !== undefined ? user : ({} as User);
                this.canChangeRingThicknesses = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_MEASUREMENT_DATA')
                    ? true
                    : false;
                this.canSendComplaint = this.user?.permissions?.find((permission: string) => permission === 'CAN_RETRIGGER_AN_ORDER_IMPORT')
                    ? true
                    : false;
                this.canChangeState = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_STATE') ? true : false;
                this.canChangeDeliveryDate = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_DELIVERY_DATE')
                    ? true
                    : false;
                this.canChangePredictedDeliveryDate = this.user?.permissions?.find(
                    (permission: string) => permission === 'CAN_CHANGE_PREDICTED_DELIVERY_DATE'
                )
                    ? true
                    : false;
                this.canChangeRingReceived = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_RING_RECEIVED')
                    ? true
                    : false;
                this.canChangeStateToRejected = this.user?.permissions?.find((permission: string) => permission === 'CAN_CHANGE_STATE_TO_REJECTED')
                    ? true
                    : false;
                this.canToggleOversizedFlag = this.user?.permissions?.find((permission: string) => permission === 'CAN_TOGGLE_OVERSIZE_FLAG')
                    ? true
                    : false;
                this.canToggleRawMaterialMissingFlag = this.user?.permissions?.find(
                    (permission: string) => permission === 'CAN_TOGGLE_RAW_MATERIAL_MISSING_FLAG'
                )
                    ? true
                    : false;
                this.canExecuteIncomingGoodsBooking = this.user?.permissions?.find((permission: string) => permission === 'CAN_EXECUTE_INCOMING_GOODS_BOOKING')
                    ? true
                    : false;
                this.isAdmin = this.user?.memberOfGroups?.find((group: string) => group === 'ZEISS_CONTRIBUTER') ? true : false;
                this.userLocale = this.user?.language;
            },
        });
    }

    public getActualThicknessesForRow(row: GridRow, index: number): number | string {
        const data: number[] | undefined = row.data.measurementData?.ringThicknessActual;

        if (data && index < data.length && data[index] > 0) {
            return data[index];
        }
        return '';
    }

    public getStateString(order: Order): string {
        const state: string = this.stateTranslationService.getValueTranslation(order.details.state);
        if (state) {
            return state;
        }
        return '';
    }

    public getDateString(order: Order, deliveryDate: boolean): string {
        const date: string | null = deliveryDate
            ? this.datePipe.transform(order.desiredDeliveryDate, 'dd.MM.yyyy HH:mm')
            : order.details.predictedDeliveryDate
            ? this.datePipe.transform(order.details.predictedDeliveryDate, 'dd.MM.yyyy HH:mm')
            : null;
        if (date) {
            return date;
        }

        return '';
    }

    public showValueAsText(orderId: string): boolean {
        return orderId !== this.orderToEdit;
    }

    public isOversized(order: OrderSearchResultItem): boolean {
        return order.isOrderOverSized;
    }

    public rawMaterialMissing(order: OrderSearchResultItem): boolean {
        return order.rawMaterialMissing;
    }

    public hasDelayedDelivery(order: OrderSearchResultItem): boolean {
        return order.hasDelayedDelivery;
    }

    public isStateShippedOrRejectedAndNoAdmin(order: IOrder): boolean {
        return this.utilService.isStateShippedOrRejectedAndNoAdmin(order, this.isAdmin);
    }

    public isDelta(order: IOrder): boolean {
        return order.productCategory == ProductCategory.Optikring || order.productCategory == ProductCategory.Erdteil;
    }

    public getTargetThicknessesForRow(row: GridRow): number[] {
        return row.data.recipeData?.ringThicknessTarget || [];
    }

    public getTargetThicknessesUnitForRow(row: GridRow): UnitOfMeasure {
        return row.data.recipeData?.ringThicknessTargetUnit;
    }

    public isAbstimmringColumn(row: GridRow) {
        return row.data.productCategory == ProductCategory.Abstimmring;
    }

    public isRingReceived(order: OrderSearchResultItem): boolean {
        return order.ringReceived;
    }

    public enableInputs(order: OrderSearchResultItem): void {
        order?.measurementData?.ringThicknessActual?.forEach((ringDepth: number, index: number) => {
            this.actualRingThicknesses[index] = ringDepth + '';
        });
        this.orderChangeForm = this.getStateForm(
            this.utilService.isNumber(order.details.state!) ? OrderStates[order.details.state!] : (order.details.state as OrderStates)
        );
        this.orderToEdit = order.id;
        this.deliveryDateChangeForm = this.getDateFormDeliveryDate(order.desiredDeliveryDate);
        this.predictedDeliveryDateChangeForm = this.getDateFormPredictedDeliveryDate(order, order.details.predictedDeliveryDate);
    }

    private getStateForm(state: OrderStates | string): FormGroup {
        const form: FormGroup = this.formBuilder.group({
            state: [[state]],
        });

        const stateControl: AbstractControl | null = form.get('state');

        if (stateControl) {
            if (
                !this.canChangeState ||
                ((state === OrderStates.Shipped || state === 'Shipped' || state === OrderStates.Rejected || state === 'Rejected') && !this.isAdmin)
            ) {
                stateControl?.disable();
            } else {
                stateControl?.enable();
            }
        }

        return form;
    }

    private getDateFormDeliveryDate(date: Date): FormGroup {
        const form: FormGroup = this.formBuilder.group({
            deliveryDate: [new Date(date)],
            deliveryTime: [new Date(date)],
        });

        const dateControl: AbstractControl | null = form.get('deliveryDate');
        const timeControl: AbstractControl | null = form.get('deliveryTime');

        if (dateControl && timeControl) {
            if (!this.canChangeDeliveryDate) {
                dateControl?.disable();
                timeControl.disable();
            } else {
                dateControl?.enable();
                timeControl?.enable();
            }
        }

        return form;
    }

    private getDateFormPredictedDeliveryDate(order: IOrder, date: Date | undefined): FormGroup {
        const form: FormGroup = this.formBuilder.group({
            predictedDeliveryDate: [date ? new Date(date) : null],
            predictedDeliveryTime: [date ? new Date(date) : null],
        });

        const dateControl: AbstractControl | null = form.get('predictedDeliveryDate');
        const timeControl: AbstractControl | null = form.get('predictedDeliveryTime');

        if (dateControl && timeControl) {
            if (!this.canChangePredictedDeliveryDate || this.isStateShippedOrRejectedAndNoAdmin(order)) {
                dateControl?.disable();
                timeControl.disable();
            } else {
                dateControl?.enable();
                timeControl?.enable();
            }
        }

        return form;
    }

    public disableInputs(): void {
        this.orderToEdit = '';
        this.actualRingThicknesses = [];
    }

    public async saveChanges(orderId: string): Promise<void> {
        const convertThicknesses: number[] = [];
        this.actualRingThicknesses.forEach((thickness: string, index: number) => {
            if (thickness !== '') convertThicknesses[index] = parseFloat(thickness);
        });

        const changedOrder: Order | undefined = this.getOrderFromOrderResultList(orderId);

        if (changedOrder) {
            if (this.whereActualRingThicknessesChanged(changedOrder.measurementData.ringThicknessActual)) {
                this.orderService
                    .updateActualRingThicknessesInDataGrid(orderId, { ringThicknessActual: convertThicknesses } as MeasurementData)
                    .then(() => {
                        this.toastService.emitSuccess({ message: 'ORDER_OVERVIEW.SAVE_CHANGES' });
                        this.changeOrderDetails(changedOrder, convertThicknesses);
                    });
            } else {
                await this.changeOrderDetails(changedOrder, convertThicknesses);
            }
        }

        this.disableInputs();
    }

    public isNumber(value: string | number): boolean {
        return this.utilService.isNumber(value);
    }

    private getDateTimeDeliveryDate(): Date {
        const date: Date = this.deliveryDateChangeForm.value.deliveryDate as Date;
        const time: Date = this.deliveryDateChangeForm.value.deliveryTime as Date;

        date.setHours(time.getHours());
        date.setMinutes(time.getMinutes());
        date.setSeconds(0);
        date.setMilliseconds(0);
        return date;
    }

    private getDateTimePredictedDeliveryDate(): Date | string {
        const date: Date = this.predictedDeliveryDateChangeForm.value.predictedDeliveryDate as Date;
        const time: Date = this.predictedDeliveryDateChangeForm.value.predictedDeliveryTime as Date;

        if (date && time) {
            date.setHours(time.getHours());
            date.setMinutes(time.getMinutes());
            date.setSeconds(0);
            date.setMilliseconds(0);
            return date;
        } else return '';
    }

    private whereActualRingThicknessesChanged(ringThicknesses: number[]): boolean {
        let result: boolean = false;
        if ((ringThicknesses === null || ringThicknesses === undefined) && this.actualRingThicknesses.length < 1) return false;
        if (this.actualRingThicknesses.length !== ringThicknesses?.length) {
            result = true;
        } else
            ringThicknesses?.forEach((ringThickness: number, index: number) => {
                if (this.actualRingThicknesses[index] !== ringThickness.toString()) {
                    result = true;
                    return;
                }
            });
        return result;
    }

    private async changeOrderDetails(changedOrder: Order, convertThicknesses: number[]): Promise<void> {
        const state: OrderStates = parseInt(OrderStates[this.orderChangeForm.value.state]);
        const deliveryDate: Date = this.getDateTimeDeliveryDate();
        const predictedDeliveryDate: Date | string = this.getDateTimePredictedDeliveryDate();
        const orderDetails: OrderDetails = {
            state: state !== changedOrder.details.state ? state : undefined,
            deliveryDate:
                this.utilService.getTimestampStringWithMinutesPrecision(deliveryDate) !==
                this.utilService.getTimestampStringWithMinutesPrecision(changedOrder.desiredDeliveryDate)
                    ? deliveryDate
                    : undefined,
            predictedDeliveryDate:
                typeof predictedDeliveryDate !== 'string' &&
                this.utilService.getTimestampStringWithMinutesPrecision(predictedDeliveryDate) !==
                    this.utilService.getTimestampStringWithMinutesPrecision(changedOrder.details.predictedDeliveryDate)
                    ? predictedDeliveryDate
                    : undefined,
        };
        changedOrder = {
            ...changedOrder,
            desiredDeliveryDate: deliveryDate,
            details: {
                ...changedOrder.details,
                state: state,
                predictedDeliveryDate: typeof predictedDeliveryDate !== 'string' ? predictedDeliveryDate : undefined,
            },
            measurementData: { ringThicknessActual: convertThicknesses } as MeasurementData,
        };
        await this.orderService.updateOrderDetails(changedOrder.id, orderDetails);

        this.updateOrderSearchResultsDataSource();
    }

    public async toggleRingReceived(order: OrderSearchResultItem, event: Event): Promise<void> {
        if (event.target instanceof HTMLInputElement) {
            const ringReceived: boolean = event.target.checked;

            this.toggleRingReceivedForOrderId = order.id;

            await this.orderService.sendRingReceived(order.id, ringReceived);

            order.ringReceived = ringReceived;
            this.toggleRingReceivedForOrderId = undefined;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onRowPrepared(e: any): void {
        if (e.rowType === 'data' && this.isComplainedOrder(e.data)) {
            e.rowElement.find('td').css('background-color', 'rgba(234, 89, 27, 0.15)');
        }
    }

    public isComplainedOrder(order: Order): boolean {
        return !order.childId && order.parentId ? true : false;
    }

    public shouldOrdererDepartmentBeHighlighted(ordererDepartment: string): boolean {
        return (
            ordererDepartment.toUpperCase().includes('SMT-OPODJ') ||
            ordererDepartment.toUpperCase().includes('SMT-OPODI') ||
            ordererDepartment.toUpperCase().includes('SMT-OIDI') ||
            ordererDepartment.toUpperCase().includes('SMT-OIDJ')
        );
    }

    public onOrdersSelectionChanged(e: SelectionChangedEvent<OrderSearchResultItem, number>) {
        const deselectRowKeys: number[] = [];
        const dataGrid: dxDataGrid<OrderSearchResultItem, number> = e.component;

        e.selectedRowsData.forEach((item: OrderSearchResultItem) => {
            if (!this.isSelectable(item)) {
                deselectRowKeys.push(dataGrid.keyOf(item));
            }
        });

        if (deselectRowKeys.length) {
            dataGrid.deselectRows(deselectRowKeys);
        }

        if (this.selectAllCheckBox) {
            this.checkBoxUpdating = true;
            this.selectAllCheckBox.option('value', this.isSelectAll(dataGrid));
            this.checkBoxUpdating = false;
        }

        this.selectedSearchResultItems = e.selectedRowsData;
    }

    public onEditorPreparing(e: EditorPreparingEvent<OrderSearchResultItem, number>) {
        if (e.parentType === 'dataRow' && e.row && !this.isSelectable(e.row.data)) {
            e.editorOptions.disabled = true;
        }

        if (e.parentType === 'headerRow') {
            const dataGrid: dxDataGrid<OrderSearchResultItem, number> = e.component;
            e.editorOptions.value = this.isSelectAll(dataGrid);

            e.editorOptions.onInitialized = (e: InitializedEvent) => {
                if (e.component) this.selectAllCheckBox = e.component;
            };

            e.editorOptions.onValueChanged = (e: ValueChangedEvent) => {
                if (!e.event) {
                    if (e.previousValue && !this.checkBoxUpdating) {
                        e.component.option('value', e.previousValue);
                    }
                    return;
                }

                if (this.isSelectAll(dataGrid) === e.value) {
                    return;
                }

                e.value ? dataGrid.selectAll() : dataGrid.deselectAll();
                e.event.preventDefault();
            };
        }
    }

    public openBulkEditPopup(): void {
        this.orderToEdit = '';
        this.isBulkEditPopupVisible = true;
    }

    public clearSelectedSearchResultItems(): void {
        if (!this.showSelectionCheckboxes) {
            this.selectedSearchResultItems = [];
        }
    }

    public closeBulkEdit(refreshOrders: boolean): void {
        this.isBulkEditPopupVisible = false;
        this.selectedSearchResultItems = [];

        if (refreshOrders) {
            this.updateOrderSearchResultsDataSource();
        }
    }

    public closeDetailsView(): void {
        this.orderIdForDetailsView = '';
        this.isOrderDetailsViewVisible = false;
        this.location.go('orders');
    }

    private isSelectable(item: OrderSearchResultItem) {
        return (
            item.details.state!.toString() === OrderStates[OrderStates.InProduction] ||
            item.details.state!.toString() === OrderStates[OrderStates.Ordered]
        );
    }

    private isSelectAll = (dataGrid: dxDataGrid) => {
        const selectableItems: OrderSearchResultItem[] = this.orderSearchResultsDataSource.filter(this.isSelectable);
        // eslint-disable-next-line @typescript-eslint/typedef
        const selectedRowKeys = dataGrid.option('selectedRowKeys');

        if (!selectedRowKeys?.length) {
            return false;
        }

        return selectedRowKeys.length >= selectableItems.length ? true : undefined;
    };

    public updateOrderSearchResultsDataSource(): void {
        const orderSearch: OrderSearch = this.orderSearch;
        const ordersSearchService: OrderService = this.orderService;

        const mapOrder: (resultItem: Order) => OrderSearchResultItem = (resultItem: Order) => {
            const bufferItem: OrderSearchResultItem = {
                ...resultItem,
                state: '',
            };
            bufferItem.state = this.stateTranslationService.getValueTranslation(resultItem.details.state);
            return bufferItem;
        };

        this.datasource = new CustomStore({
            key: 'id',
            async load(loadOptions: LoadOptions) {
                const state: OrderState = await ordersSearchService.fetchOrderResults(orderSearch, loadOptions);
                const result: GridLoadOrderResult = state.orderResult;

                return {
                    ...result,
                    data: result.data.map(mapOrder),
                };
            },
        });
    }

    public getOrderFromOrderResultList(orderId: string): Order {
        return this.orderStore.getValue().orderResult.data.find((order: Order) => order.id === orderId)!;
    }
}
