import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-booking-submit-dialog',
    templateUrl: './booking-submit-dialog.component.html',
    styleUrls: ['./booking-submit-dialog.component.scss'],
})
export class BookingSubmitDialogComponent {
    @Output() executeIncomingGoodsBooking: EventEmitter<void> = new EventEmitter<void>();
    @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();
}
