import { Department, OrderSearch } from '../../../common/models/order.type';
import { LoadOptions } from 'devextreme/data';

export function applySearchQuery(orderSearch: OrderSearch | undefined, loadOptions: LoadOptions): LoadOptions {
    loadOptions.filter = [];

    if (!orderSearch) {
        return loadOptions;
    }
    
    if (orderSearch.supplier !== undefined && orderSearch.supplier !== null) {
        loadOptions.filter.push(['supplier', '=', orderSearch.supplier])
    }

    if (orderSearch.desiredDeliveryDate?.from) {
        loadOptions.filter.push(['body.deliveryDate', '>=', orderSearch.desiredDeliveryDate.from]);
    }

    if (orderSearch.desiredDeliveryDate?.to) {
        orderSearch.desiredDeliveryDate.to.setHours(24, 0, 0, -1);
        loadOptions.filter.push(['body.deliveryDate', '<=', orderSearch.desiredDeliveryDate.to]);
    }

    if (orderSearch.complainedOrders) loadOptions.filter.push(['childId', null], ['parentId', '<>', null]);

    if (orderSearch.set) loadOptions.filter.push(['body.set', 'contains', orderSearch.set]);

    if (orderSearch.ordererDepartments && orderSearch.ordererDepartments.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const ordererDepartmentsFilter: any = [];
        orderSearch.ordererDepartments.forEach((ordererDepartment: Department) => {
            ordererDepartmentsFilter.push(['body.department', '=', Department[ordererDepartment]], 'or');
        });
        loadOptions.filter.push([ordererDepartmentsFilter]);
    }

    if (orderSearch.states && orderSearch.states.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const statesFilter: any = [];
        orderSearch.states.forEach((state: string) => {
            statesFilter.push(['body.details.state', '=', state], 'or');
        });
        loadOptions.filter.push([statesFilter]);
    }

    if (orderSearch.productCategories && orderSearch.productCategories.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const productCategoriesFilter: any = [];
        orderSearch.productCategories.forEach((productCategory: string) => {
            productCategoriesFilter.push(['body.productCategory', '=', productCategory], 'or');
        });
        loadOptions.filter.push([productCategoriesFilter]);
    }

    return loadOptions;
}
