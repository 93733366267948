import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { Order, OrderSearch } from 'src/app/common/models/order.type';

@Component({
    selector: 'app-orders-search',
    templateUrl: './order-search.component.html',
    styleUrls: ['./order-search.component.scss'],
})
export class OrderSearchComponent implements OnDestroy, OnInit {
    public orderSearch: OrderSearch | undefined;
    
    private destroySearch$ = new Subject<void>();
    private subscription!: Subscription;

    public selectedOrder: string | undefined;

    constructor(readonly router: Router, readonly route: ActivatedRoute) {}
    
    ngOnInit(): void {
        this.subscription = this.route.paramMap.subscribe((param: ParamMap) => {
            this.selectedOrder = param.get('id')!;
        });
    }

    ngOnDestroy(): void {
        this.destroySearch$.next();
        this.subscription.unsubscribe();
    }

    public onOrderRowClickEvent(rowData: Order): void {
        const id: string = rowData.id;
        this.router.navigate(['/orders/order', id]);
    }

    public submitSearch(orderSearch: OrderSearch) {
        this.orderSearch = { ...orderSearch };
    }
}
