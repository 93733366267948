<div class="context-data-container">
    <div class="left-column">
        <div class="field-container">
            <div class="label">
                <label for="orderId">
                    {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.ORDER_ID' | translate }}:
                </label>
            </div>
            <div class="field">
                {{ order.orderNumber }}
            </div>
        </div>

        <div class="field-container">
            <div class="label">
                <label for="material">
                    {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.MATERIAL' | translate }}:
                </label>
            </div>
            <div class="field">
                {{ order.materialNumber }}
            </div>
        </div>

        <div class="field-container">
            <div class="label">
                <label for="objectiveType">
                    {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.OBJECTIVE_TYPE' | translate }}:
                </label>
            </div>
            <div class="input-field">{{ order.objectiveType }}</div>
        </div>

        <div class="field-container">
            <div class="label">
                <label for="set">{{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.SET' | translate }}</label>
            </div>
            <div class="field">
                {{ order.set }}
            </div>
        </div>
    </div>

    <div class="right-column">
        <div class="field-container">
            <div class="label">
                <label for="bestellauslöser">
                    {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.ORDER_TRIGGER' | translate }}:
                </label>
            </div>
            <div class="field">
                {{ order.ordererFirstname + ' ' + order.ordererLastname  }}
            </div>
        </div>

        <div class="field-container">
            <div class="label">
                <label for="productionJob">
                    {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.PRODUCTION_JOB' | translate }}:
                </label>
            </div>
            <div class="field">
                {{ order.sapProductionOrderNumber }}
            </div>
        </div>

        <div class="field-container">
            <div class="label">
                <label for="orderPosition">
                    {{ 'ORDER_DETAIL.ORDER_CONTEXT_DATA.ORDER_POSITION' | translate }}:
                </label>
            </div>
            <div class="field">
                {{ order.orderPosition }}
            </div>
        </div>

        <div class="field-container">
          <div class="label">
              <label for="baeSerNr">
                  {{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.TOOLTIPS.BAE_SERIAL_NUMBER' | translate }}:
              </label>
          </div>
          <div class="field">
              {{ order.baeSerNr }}
          </div>
      </div>
    </div>
</div>
