import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { 
    Department,
    OrderSearch,
    OrderStateMapping,
    PdxSupplier,
    ProductCategory
} from 'src/app/common/models/order.type';
import { OrderQuery } from 'src/app/main/store/order/order.query';
import { AuthQuery } from "../../../../store/auth/auth.query";
import { AuthState } from "../../../../../common/models/authState.type";

@Component({
    selector: 'app-order-search-bar',
    templateUrl: './order-search-bar.component.html',
    styleUrls: ['./order-search-bar.component.scss'],
})
export class OrderSearchBarComponent {
    @Input() disableSearchButton = false;
    @Output() searchRequestSubmit$: EventEmitter<OrderSearch> = new EventEmitter<OrderSearch>();

    private searchTimeout?: NodeJS.Timeout | number;
    public isRefreshSearchDialogVisible = false;

    public searchForm: FormGroup;
    public productCategories: string[] = Object.keys(ProductCategory).filter((key: string) => isNaN(Number(key)) && key !== 'None');

    public ordererDepartments: string[] = [
        Department[Department.Montage],
        Department[Department.Justage],
        Department[Department.Wetzlar],
        Department[Department.OPK],
    ];
    private orderStateTranslationPrefix: string = 'ORDERS_OVERVIEW.FORM.STATE.VALUES.';
    public orderStates: OrderStateMapping[] = [
        { value: 'InProduction', displayName: this.orderStateTranslationPrefix + 'IN_PRODUCTION' },
        { value: 'Shipped', displayName: this.orderStateTranslationPrefix + 'SHIPPED' },
        { value: 'Ordered', displayName: this.orderStateTranslationPrefix + 'ORDERED' },
        { value: 'Rejected', displayName: this.orderStateTranslationPrefix + 'REJECTED' },
    ];

    public suppliers?: { value: number; text: string; }[] = [];

    constructor(private formBuilder: FormBuilder, private orderQuery: OrderQuery, readonly authQuery: AuthQuery) {
        this.searchForm = this.getFormGroup();
        
        this.authQuery.authState$.subscribe((state: AuthState) => {
            this.suppliers = state.allowedPdxSuppliers.map((supplier: PdxSupplier) => ({ value: supplier, text: PdxSupplier[supplier] }));
        });
    }

    public submitSearch(): void {
        this.searchRequestSubmit$.next(this.searchForm.value as OrderSearch);
        this.setRefreshSearchTimer();
    }

    public closeRefreshSearchDialog(refresh: boolean) {
        this.isRefreshSearchDialogVisible = false;

        if (refresh) {
            this.submitSearch();
        }
    }

    public setRefreshSearchTimer() {
        if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
        }

        this.searchTimeout = setTimeout(() => {
            this.isRefreshSearchDialogVisible = true;
        }, 600000);
    }

    public clearAllFormControls(): void {
        this.searchForm.setValue({
            complainedOrders: false,
            set: '',
            supplier: null,
            ordererDepartments: [],
            states: [],
            desiredDeliveryDate: {
                from: null,
                to: null,
            },
            productCategories: [],
        });
    }

    private getFormGroup(): FormGroup {
        // eslint-disable-next-line jsdoc/require-jsdoc
        function getDefaultFilter() {
            const from: Date = new Date();
            from.setHours(0, 0, 0, 0);
            from.setDate(from.getDate() - 1);

            const to: Date = new Date();

            return {
                desiredDeliveryDate: {
                    from: from,
                    to: to,
                },
                supplier: PdxSupplier.PTS
            };
        }

        const { complainedOrders, set, ordererDepartments, states, productCategories } = this.orderQuery.searchQuery || {};
        const { desiredDeliveryDate, supplier } = this.orderQuery.searchQuery || getDefaultFilter();

        return this.formBuilder.group({
            complainedOrders: [complainedOrders || false],
            set: [set || ''],
            ordererDepartments: [ordererDepartments || []],
            states: [states || []],
            supplier: supplier,
            desiredDeliveryDate: this.formBuilder.group({
                from: desiredDeliveryDate?.from ? new Date(desiredDeliveryDate.from) : null,
                to: desiredDeliveryDate?.to ? new Date(desiredDeliveryDate.to) : null,
            }),
            productCategories: [productCategories || []],
        });
    }
}
