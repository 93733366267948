import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-refresh-search-dialog',
    templateUrl: './refresh-search-dialog.component.html',
    styleUrls: ['./refresh-search-dialog.component.scss'],
})
export class RefreshSearchDialogComponent {
    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
}
