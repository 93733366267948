<zui-page-header>
    <zui-title-stack size="m">
        <h1>{{ 'ORDERS_OVERVIEW.TITLE' | translate }}</h1>
    </zui-title-stack>
</zui-page-header>

<div class="styled-border search-container">
    <app-order-search-bar (searchRequestSubmit$)="submitSearch($event)"></app-order-search-bar>

    <ng-container *ngIf="orderSearch || selectedOrder">
        <app-order-data-grid
            (orderRowClick$)="onOrderRowClickEvent($event)"
            [orderSearch]="orderSearch!"
            [orderIdForDetailsView]="selectedOrder"
        ></app-order-data-grid>
    </ng-container>
        <div
            *ngIf="!orderSearch"
            class="search-message"
        >
            <zui-inline-message>
                {{ 'ORDERS_OVERVIEW.NO_SEARCH_RESULT' | translate }}
                <zui-icon-alerts-ewi-info slot="icon"></zui-icon-alerts-ewi-info>
            </zui-inline-message>
        </div>
</div>
