<ng-container *ngIf="order.incomingGoodsBookingRequestStatus === INCOMING_GOODS_BOOKING_REQUEST_STATUS.NotRequested">
    <dx-button *ngIf="canExecuteIncomingGoodsBooking; else notAuthorized" (onClick)="showSubmitDialog()">{{
        'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.BUTTONS.BOOKING' | translate }}</dx-button>
    <ng-template #notAuthorized>{{
        'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.NOT_BOOKED' | translate }}</ng-template>
</ng-container>

<ng-container *ngIf="order.incomingGoodsBookingRequestStatus === INCOMING_GOODS_BOOKING_REQUEST_STATUS.Requested">
    <div *ngIf="canExecuteIncomingGoodsBooking; else notAuthorized">
        {{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.REQUESTED' | translate }}
    </div>
    <ng-template #notAuthorized>{{
        'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.NOT_BOOKED' | translate }}</ng-template>
</ng-container>

<ng-container *ngIf="order.incomingGoodsBookingRequestStatus === INCOMING_GOODS_BOOKING_REQUEST_STATUS.Failed">
    <div *ngIf="canExecuteIncomingGoodsBooking; else notAuthorized">
        <div class="container error">
            <i class="dx-icon-clear"></i>
            <u (click)="showErrorDetailsDialog('error')">
                {{ 'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.FAILED' | translate }}
            </u>
        </div>
        <dx-button (onClick)="showSubmitDialog()">{{
            'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.BUTTONS.RETRY' | translate }}</dx-button>
    </div>
    <ng-template #notAuthorized>{{
        'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.NOT_BOOKED' | translate }}</ng-template>
</ng-container>

<ng-container *ngIf="order.incomingGoodsBookingRequestStatus === INCOMING_GOODS_BOOKING_REQUEST_STATUS.Confirmed">
    <div (click)="showErrorDetailsDialog('details')">
        <div>
            {{ order.incomingGoodsBookingRequestBy?.firstName }} {{ order.incomingGoodsBookingRequestBy?.lastName }}
        </div>
        <div>
            {{ order.incomingGoodsBookingRequestAt | date : "dd.MM.yyyy HH:mm" }}
        </div>
        <div class="container">
            <i class="dx-icon-info"></i> <u>{{
                'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.MORE_INFORMATION' | translate }}</u>
        </div>
    </div>
</ng-container>

<dx-popup [(visible)]="isSubmitDialogVisible" [hideOnOutsideClick]="false" [showTitle]="true" [dragEnabled]="false"
    [title]="'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.SUBMIT_DIALOG.TITLE' | translate"
    [height]="250" [width]="420" [resizeEnabled]="false">
    <app-booking-submit-dialog (closeDialog)="hideSubmitDialog()"
        (executeIncomingGoodsBooking)="executeIncomingGoodsBookingRequest()"></app-booking-submit-dialog>
</dx-popup>

<dx-popup [(visible)]="isErrorDetailsDialogVisible" [hideOnOutsideClick]="false" [showTitle]="true"
    [dragEnabled]="false"
    [title]="'ORDERS_OVERVIEW.ORDER_DATA_GRID.INCOMINGGOODSBOOKING_COMPONENT.ERRORDETAILS_DIALOG.TITLE' | translate"
    [height]="250" [width]="480" [resizeEnabled]="true">
    <app-booking-details-error-dialog (closeDialog)="hideErrorDetailsDialog()"
        [errorOrDetails]="order.incomingGoodsBookingRequestErrors ? 'error' : 'details'"
        [errors]="order.incomingGoodsBookingRequestErrors"
        [details]="bookingDetails"></app-booking-details-error-dialog>
</dx-popup>